import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArrow, LazyAtomsButton, LazyAtomsLoading, LazyAtomsPrimaryButton, LazyAtomsVideoPlayer, LazyError, LazyFullScreenLoading, LazyFullScreenLoadingHippo, LazyModalLoading, LazyMoleculesMultipleChoiceForm, LazyMoleculesPaginationButton, LazyMoleculesVideoTitle, LazyOrganismsButtonLoading, LazyOrganismsCertificate, LazyOrganismsChapters, LazyOrganismsFooter, LazyOrganismsGrade, LazyOrganismsHeader, LazyOrganismsModal, LazyOrganismsShareModal, LazyQuestionnaire, LazyTemplatesLoading, LazyUiButton, LazyUnauthorized, LazyVideoLoading, LazyWelcome, LazyWelcomeButtons, LazySvgoHippo, LazySvgoLinkedIn, LazySvgoLoading, LazySvgoLogo, LazySvgoPaper, LazySvgoWatermark } from '#components'
const lazyGlobalComponents = [
  ["Arrow", LazyArrow],
["AtomsButton", LazyAtomsButton],
["AtomsLoading", LazyAtomsLoading],
["AtomsPrimaryButton", LazyAtomsPrimaryButton],
["AtomsVideoPlayer", LazyAtomsVideoPlayer],
["Error", LazyError],
["FullScreenLoading", LazyFullScreenLoading],
["FullScreenLoadingHippo", LazyFullScreenLoadingHippo],
["ModalLoading", LazyModalLoading],
["MoleculesMultipleChoiceForm", LazyMoleculesMultipleChoiceForm],
["MoleculesPaginationButton", LazyMoleculesPaginationButton],
["MoleculesVideoTitle", LazyMoleculesVideoTitle],
["OrganismsButtonLoading", LazyOrganismsButtonLoading],
["OrganismsCertificate", LazyOrganismsCertificate],
["OrganismsChapters", LazyOrganismsChapters],
["OrganismsFooter", LazyOrganismsFooter],
["OrganismsGrade", LazyOrganismsGrade],
["OrganismsHeader", LazyOrganismsHeader],
["OrganismsModal", LazyOrganismsModal],
["OrganismsShareModal", LazyOrganismsShareModal],
["Questionnaire", LazyQuestionnaire],
["TemplatesLoading", LazyTemplatesLoading],
["UiButton", LazyUiButton],
["Unauthorized", LazyUnauthorized],
["VideoLoading", LazyVideoLoading],
["Welcome", LazyWelcome],
["WelcomeButtons", LazyWelcomeButtons],
["SvgoHippo", LazySvgoHippo],
["SvgoLinkedIn", LazySvgoLinkedIn],
["SvgoLoading", LazySvgoLoading],
["SvgoLogo", LazySvgoLogo],
["SvgoPaper", LazySvgoPaper],
["SvgoWatermark", LazySvgoWatermark],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
