import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin(({ vueApp }) => {
  const {
    public: { sentry: { dsn } },
    app: { env = 'production' }
  } = useRuntimeConfig();

  const isDev = env === 'development';

  if (!dsn) {
    console.warn('Sentry DSN is not configured');
    return provideSentryNull();
  }

  const router = useRouter();

  try {
    const sentryConfig = {
      app: vueApp,
      dsn,
      environment: env,
      trackPinia: true,
      debug: isDev,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.browserProfilingIntegration(),
      ],
      tracesSampleRate: isDev ? 1.0 : 0.1,
      profilesSampleRate: isDev ? 1.0 : 0.1,
      beforeSend(event) {
        if (isDev) {
          console.log('Sentry event:', event);
        }
        return event;
      },
    };

    const sentry = Sentry.init(sentryConfig);

    return {
      provide: {
        sentry,
      },
    };
  } catch (error) {
    console.error('Failed to initialize Sentry:', error);
    return provideSentryNull();
  }
});

function provideSentryNull() {
  return {
    provide: {
      sentry: null,
    },
  };
}